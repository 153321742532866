import React from "react"
import PropTypes from "prop-types"

import Layout from "../components/layout"
import Seo from "../components/seo"
import InstallationGuideMobile from "../components/util/InstallationGuideMobile"
import InstallationGuideDesktop from "../components/util/InstallationGuideDesktop"

import Box from "@mui/material/Box"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import { Grid } from "@mui/material"

import Typography from "@mui/material/Typography"
import Link from "@mui/material/Link"

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="flex-start"
          style={{ minHeight: "100vh" }}
        >
          <Grid item p={1} pt={4}>
            <Typography component="div">{children}</Typography>
          </Grid>
          <Grid item p={1} pt={4}>
            <Link
              target="_blank"
              href="https://support.google.com/chrome/answer/9658361?hl=en&co=GENIE.Platform%3DAndroid&oco=0"
              underline="hover"
            >
              Learn more on installing Progressive Web Apps (PWAs)
            </Link>
          </Grid>
        </Grid>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

const InstallationGuide = () => {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <Layout>
        <Seo
          title="App Installation Guide"
          description="Install loanprepayplanner.com as a first class app in mobile or desktop to access the prepay plans with ease"
          keywords="pwa, progressive web app, progressive web application, install, app, download, offline, application"
        />
        <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
          <Tabs value={value} onChange={handleChange} centered>
            <Tab label="Install on desktop" {...a11yProps(0)} />
            <Tab label="Install on mobile" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <InstallationGuideDesktop />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <InstallationGuideMobile />
        </TabPanel>
      </Layout>
    </>
  )
}

export default InstallationGuide
