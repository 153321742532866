import React from "react"

import { useTheme } from "@mui/material/styles"
import Box from "@mui/material/Box"
import MobileStepper from "@mui/material/MobileStepper"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight"
import SwipeableViews from "react-swipeable-views"
import { StaticImage } from "gatsby-plugin-image"

export const images = [
  {
    label: "Open additional options from browser's address bar",
  },
  {
    label: "Choose 'Install app' from the menu",
  },
  {
    label: "Select 'Install' on the prompt",
  },
  {
    label: "Application will be available in launcher",
  },
  {
    label: "You can open application directly from launcher!",
  },
  {
    label:
      "If you wish to uninstall, process is similar as any other application in your device",
  },
]

const InstallationGuideMobile = () => {
  const theme = useTheme()
  const [activeStep, setActiveStep] = React.useState(0)
  const maxSteps = images.length

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleStepChange = step => {
    setActiveStep(step)
  }

  return (
    <Box sx={{ maxWidth: 350, flexGrow: 1 }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          height: 60,
          pl: 1,
          pb: 3,
          bgcolor: "background.default",
        }}
      >
        <Typography>{images[activeStep].label}</Typography>
      </Paper>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        <div>
          <StaticImage
            layout="constrained"
            formats={["auto", "webp", "avif"]}
            src="../../images/install-guide/mobile/Step1.jpeg"
            quality={100}
            alt="Open additional options from browser's address bar"
          />
        </div>

        <div>
          <StaticImage
            layout="constrained"
            formats={["auto", "webp", "avif"]}
            src="../../images/install-guide/mobile/Step2.jpeg"
            quality={100}
            alt="Choose 'Install app' from the menu"
          />
        </div>

        <div>
          <StaticImage
            layout="constrained"
            formats={["auto", "webp", "avif"]}
            src="../../images/install-guide/mobile/Step3.jpeg"
            quality={100}
            alt="Select 'Install' on the prompt"
          />
        </div>

        <div>
          <StaticImage
            layout="constrained"
            formats={["auto", "webp", "avif"]}
            src="../../images/install-guide/mobile/Step4.jpeg"
            quality={100}
            alt="Application will be available in launcher"
          />
        </div>

        <div>
          <StaticImage
            layout="constrained"
            formats={["auto", "webp", "avif"]}
            src="../../images/install-guide/mobile/Step5_pwa.jpeg"
            quality={100}
            alt="You can open application directly from launcher!"
          />
        </div>

        <div>
          <StaticImage
            layout="constrained"
            formats={["auto", "webp", "avif"]}
            src="../../images/install-guide/mobile/Step6_uninstall.jpeg"
            quality={100}
            alt="If you wish to uninstall, process is similar as any other application in your device"
          />
        </div>
      </SwipeableViews>
    </Box>
  )
}

export default InstallationGuideMobile
